
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://namcor.unicomms.app"
          target="_blank"
          rel="noopener noreferrer"
        >
          If the page did not redirect to the new url, click here
        </a>
      </header>
    </div>
  );
}

export default App;
